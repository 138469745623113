import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  dataStatus: "idle",
  dataInfo: null,
  dataError: null,
};

export const getDataExport = createAsyncThunk(
  "admin/getDataExport",
  async ({ token, model, query }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/admin/exportDataToAdmin/${model}/${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      if (model == "userDetails") {
        a.download = "userDetails.csv";
      } else if (model == "productMasters") {
        a.download = "productDetails.csv";
      } else if (model == "checkouts") {
        a.download = "orderDetails.csv";
      }
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      return new Blob(data);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const adminDataExportSlice = createSlice({
  name: "getDataExport",
  initialState,
  reducers: {
    reset(state, action) {
      state.dataInfo = null;
      state.dataStatus = "idle";
      state.dataError = null;
    },
  },
  extraReducers: {
    [getDataExport.pending]: (state, action) => {
      state.dataStatus = "loading";
    },
    [getDataExport.fulfilled]: (state, action) => {
      state.dataStatus = "succeeded";
      state.dataInfo = action.payload.product;
    },
    [getDataExport.rejected]: (state, action) => {
      state.dataStatus = "failed";
      state.dataError = action.payload.message;
    },
  },
});

export const { reset } = adminDataExportSlice.actions;
export default adminDataExportSlice.reducer;
