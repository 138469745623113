import React, { useState } from "react";
import "./OtherClosetItem.css";
import FP1 from "../../assets/images/LP1.png";
import hanger from "../../assets/images/hanger.svg";
import Spinner1 from "../Spinner1/Spinner1";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoClosetItem from "../../assets/images/others_closet_empty.svg";
import loadImgGif from "../../assets/images/load-img.gif";
import { PiCoatHangerFill } from "react-icons/pi";

const OtherClosetItem = ({
  userInfo,
  deletekey,
  pageNumber,
  productCount,
  othersCloset = false,
}) => {
  console.log("CLoset Info ", userInfo)
  let products = [];
  products = userInfo ? userInfo.userProductsId.productsId : [];
  return (
    <div className="my-closet-item-container shop-items-con" style={othersCloset && { margin: "0 0.75rem", padding: "0 30px"}}>
      {products.length > 0 ? (
        <>
           
            <div style={{ marginBottom: "2rem", textAlign: othersCloset ? "center" : "left" }}>
              <h1
                className="h1 bold-text background-text"
                style={{ textAlign: othersCloset ? "center": "left" }}
              >
                {othersCloset ? `${userInfo?.userDetailsId?.closetName}'s Closet` :"All Closet Items" } 
              </h1>
              <div className="h5">
                Showing {pageNumber ? pageNumber * 20 + 1 : 1}
                {"-"}
                {pageNumber && pageNumber === Math.ceil(productCount / 20) - 1
                  ? productCount
                  : pageNumber
                  ? pageNumber * 20 + 20
                  : 20}{" "}
                of {productCount} Items
              </div>
            </div>
          
          <br />
          <div className="closet-card-container shop-items-con">
            {products.length > 0 &&
              products.map((item, index) => {
                return (
                  <Link
                    to={{
                      pathname: `/product/${encodeURIComponent(
                        item.productStats.brand
                      )}-${encodeURIComponent(item.productName)}/${item._id}`,
                      // search: `?product=${item._id}`,
                    }}
                    state={{ id: item._id }}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="shop-item-card"
                      style={{
                        width: "250px",
                        margin: "0 auto",
                        height: "inherit",
                        marginBottom: "4rem",
                      }}
                    >
                      <div className="card_image" style={{ height: "270px" }}>
                        <LazyLoadImage
                          src={
                            item.productImage[0]
                              ? item.productImage[0]
                              : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                          }
                          alt="FP1"
                          effect="blur"
                          placeholderSrc={loadImgGif}
                          // style={{ height: "150px" }}
                        />
                        {/* <div className="action_icon">
                        <Link
                          to={{
                            pathname: `/product/${encodeURIComponent(
                              item.productStats.brand
                            )}-${encodeURIComponent(item.productName)}`,
                            search: `?product=${item._id}`,
                          }}
                          state={{ id: item._id }}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="btn">View</div>
                        </Link>
                        <div className="btn"><i class="fas fa-heart"></i></div>
                        <div className="btn"><i class="fas fa-search-plus"></i></div>
                      </div> */}
                      </div>
                      <div className="card_text">
                        <div className="product_title h6">
                          {item.productName}
                        </div>
                        <hr
                          style={{
                            margin: "1rem .2rem",
                            borderStyle: "solid",
                            color: "#ddd",
                          }}
                        />
                        <div className="product_price h6 bold-text">
                          {item.productHanger}
                          <PiCoatHangerFill
                            style={{ marginLeft: "3px" }}
                            size={"1.3em"}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </>
      ) : (
        <div className="no_item">
          <img src={NoClosetItem} alt="no-closet-icon" />
          <h1 className="h1 text-center" style={{ padding: "2rem 0" }}>
            No Items to Display in this Closet
          </h1>
          {/* <div className="h5 text-center" style={{ marginTop: "1rem" }}>
            <Link className="add_item" to="../add-items">
              Click Here
            </Link>{" "}
            to Add Item in your Closet
          </div> */}
        </div>
      )}
    </div>
  );
};

export default OtherClosetItem;
