import React, { useState } from "react";
import "./ClosetItem.css";
import FP1 from "../../assets/images/LP1.png";
import hanger from "../../assets/images/hanger.svg";
import Spinner1 from "../Spinner1/Spinner1";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoItemCloset from "../../assets/images/no_closet_item.svg";
import { FaUndo } from "react-icons/fa";
import { PiCoatHangerFill } from "react-icons/pi";

const ClosetItem = ({
  userInfo,
  deletekey,
  productCount,
  pageNumber,
  reactivateKey,
  searchProduct,
  resetSearch,
}) => {
  // //consolele.log(userInfo, "Asdasdspopo");
  const [productSearchVal, setProductSearchVal] = useState("");
  let products = [];
  products = userInfo ? userInfo.userProductsId.productsId : [];
  //consolele.log(products)
  return (
    <div className="my-closet-item-container">
      {products.length > 0 || productSearchVal ? (
        <div div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="input_container search_closet"
            style={{ width: "100%" }}
          >
            <div style={{ display: "flex", width: "100%", marginLeft: 0 }}>
              <input
                type="text"
                id="productName"
                name="productName"
                onChange={(e) => setProductSearchVal(e.target.value)}
                value={productSearchVal}
                placeholder="Enter Product Name"
                style={{ marginTop: "0px !important", fontFamily: "inherit" }}
              />
              <button
                type="submit"
                className="btn"
                onClick={(e) => {
                  searchProduct(productSearchVal);
                }}
              >
                <i class="fas fa-search fa-lg"></i>
              </button>
            </div>
          </div>
          {productSearchVal && (
            <span
              className="small-text"
              style={{
                marginRight: 0,
                // float: "right",
                marginTop: "5px",
                cursor: "pointer",
                color: "var(--background-color)",
                fontSize: "13px",
                fontFamily: "LATO",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
              onClick={() => {
                resetSearch();
                setProductSearchVal("");
              }}
            >
              Reset <FaUndo style={{ fontSize: "11px" }} />
            </span>
          )}
        </div>
      ) : (
        ""
      )}

      {products.length > 0 ? (
        <>
          {/* <h1 className="title h1" style={{ textAlign: "left" }}>
            All Your Items
          </h1> */}
          {/* <div className="h5">
            Showing {pageNumber * 20 + 1}
            {"-"}
            {pageNumber === Math.ceil(productCount / 20) - 1
              ? productCount
              : pageNumber * 20 + 20}{" "}
            of {productCount} Items
          </div> */}
          {/* <br /> */}

          <h1 className="h1 subtitle" style={{ textAlign: "left" }}>
            Active Items
          </h1>
          <hr style={{ marginTop: ".5rem" }} />
          <div className="closet-card-container">
            {products.length > 0 &&
              products.map((val, index) => {
                return (
                  <>
                    {!val.productArchive && !val.adminBan && (
                      <div className="card closet-cardd">
                        <Link
                          to={{
                            pathname: `/product/${encodeURIComponent(
                              val.productStats.brand
                            )}-${encodeURIComponent(val.productName)}/${
                              val._id
                            }`,
                            // search: `?product=${val._id}`,
                          }}
                          state={{ id: val._id }}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className="card_image"
                            style={{ position: "relative", paddingBottom: "0" }}
                          >
                            <LazyLoadImage
                              src={
                                val.productImage[0]
                                  ? val.productImage[0]
                                  : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                              }
                              alt="FP1"
                              effect="blur"
                              style={{ height: "150px" }}
                            />
                            <div
                              className="action_icon"
                              style={{
                                position: "absolute",
                                bottom: "1rem",
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              <Link
                                to={`/editproduct/${encodeURIComponent(
                                  val.productStats.brand
                                )}-${encodeURIComponent(val.productName)}`}
                                state={{ id: val._id }}
                                style={{ textDecoration: "none" }}
                              >
                                <div className="btn">
                                  <i class="fas fa-pen"></i>
                                </div>
                              </Link>
                              <Link to={"/user/my-closet"}>
                                <div
                                  className="btn"
                                  onClick={() => {
                                    deletekey(val._id);
                                  }}
                                >
                                  <i class="fas fa-trash"></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="card_text">
                            <div className="product_title h6">
                              {val.productName}
                            </div>
                            <hr
                              style={{
                                margin: "1rem .2rem",
                                borderStyle: "solid",
                                color: "#ddd",
                              }}
                            />
                            <div className="product_price h6 bold-text">
                              {val.productHanger}
                              <PiCoatHangerFill
                                style={{ marginLeft: "3px" }}
                                size={"1.3em"}
                              />{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </>
                );
              })}

            {products.length > 0 &&
            products.every(
              (product) =>
                product.productArchive === true || product.adminBan === true
            ) ? (
              <div
                style={{ marginTop: "1rem", marginBottom: "4rem" }}
                className="no_item"
              >
                <h3
                  className="h3"
                  style={{ marginTop: "1rem", color: "var(--grey-color)" }}
                >
                  No Active Items in your Closet
                </h3>
                <div className="h6" style={{ marginTop: "1rem" }}>
                  <Link className="add_item" to="/add-items">
                    <button className="btn">Add Product in your Closet</button>
                  </Link>{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <br />
          <h1 className="h1 subtitle" style={{ textAlign: "left" }}>
            Archived Items
          </h1>
          {/* <div className="subtitle"></div> */}
          <hr style={{ marginTop: ".5rem" }} />
          <div className="closet-card-container">
            {products.length > 0 &&
              products.map((val, index) => {
                return (
                  <>
                    {(val.productArchive || val.adminBan) && (
                      <div
                        className="card"
                        style={
                          val.adminBan ||
                          userInfo.userDetailsId.membership == "trial"
                            ? { opacity: 0.6, cursor: "no-drop" }
                            : {}
                        }
                        title={
                          val.adminBan ||
                          userInfo.userDetailsId.membership == "trial"
                            ? "Product is Banned by Admin"
                            : ""
                        }
                      >
                        <div
                          className="card_image"
                          style={{ position: "relative", paddingBottom: 0 }}
                        >
                          <LazyLoadImage
                            src={
                              val.productImage[0]
                                ? val.productImage[0]
                                : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                            }
                            alt="FP1"
                            effect="blur"
                            style={{ height: "150px" }}
                          />
                          <div
                            className="action_icon"
                            style={{
                              position: "absolute",
                              left: "102px",
                              bottom: "1rem",
                            }}
                          >
                            {/* <div className="btn">
                            <i class="fas fa-share-alt"></i>
                          </div>
                          <Link
                            to={`/editproduct/${val.productStats.brand}-${val.productName}`}
                            state={{ id: val._id }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="btn">
                              <i class="fas fa-pen"></i>
                            </div>
                          </Link> */}
                            {!val.adminBan &&
                              userInfo.userDetailsId.membership !== "trial" && (
                                <div
                                  className="btn"
                                  onClick={() => {
                                    reactivateKey(val._id);
                                  }}
                                >
                                  <i
                                    class="fas fa-trash-restore-alt"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="card_text">
                          <div className="product_title h6">
                            {val.productName}
                          </div>
                          <hr
                            style={{
                              margin: "1rem .2rem",
                              borderStyle: "solid",
                              color: "#ddd",
                            }}
                          />
                          <div className="product_price h6 bold-text">
                            {val.productHanger}
                            <PiCoatHangerFill
                              style={{ marginLeft: "3px" }}
                              size={"1.3em"}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            {products.length > 0 &&
            products.every(
              (product) =>
                product.productArchive === false && product.adminBan === false
            ) ? (
              <div
                className="no_item"
                style={{ marginTop: "1rem", marginBottom: "4rem" }}
              >
                <h1 className="h1" style={{ marginTop: "1rem" }}>
                  No Archived Items in your Closet
                </h1>
                {/* <div className="h5" style={{ marginTop: "1rem" }}>
                      <Link className="add_item" to="/add-items">
                        <button className="btn">Add Product in your Closet</button>
                      </Link>{" "}
                    </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <div className="no_item">
          <img alt="no items illustrate" src={NoItemCloset} />
          <h1 className="h1" style={{ marginTop: "1rem" }}>
            No Items in your Closet ?
          </h1>
          <div className="h5" style={{ marginTop: "1rem" }}>
            {userInfo &&
            userInfo.userDetailsId &&
            userInfo.userDetailsId.membership == "trial" ? (
              <Link className="add_item" to="/user/membership">
                <button className="btn">Purchase Membership to get Paid</button>
              </Link>
            ) : (
              <Link className="add_item" to="/add-items">
                <button className="btn">Add Product in your Closet</button>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClosetItem;
