import React, { useEffect, useState } from "react";
import "./Leaderboard.css"; // CSS file for styling
import Spinner from "../components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderboardResults } from "../components/StateSlices/getLeaderboardResultsSlice";
import { Link } from "react-router-dom";

const Leaderboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const { status, leaderboardResults, error } = useSelector((state) => state.getleaderboardresults);
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    // Simulate an API request
    dispatch(getLeaderboardResults({}))
    setLoading(true);
  }, []);

  useEffect(() => {
    if(leaderboardResults){
      setLoading(false)
      setData(leaderboardResults)
    }
  }, [leaderboardResults])

  return (
    <div style={{minHeight: "40vh"}}>
      {loading ? (
        <Spinner />
      ) : (
        <section>
          <div className="leaderboard-container">
            <h2 className="leaderboard-title">
              ClosestCloset Impact Score Leaderboard
            </h2>
            <div className="leaderboard-table site-width">
              {data.map((item, index) => (
                <div key={item.id} className="leaderboard-row">
                  <div className="tile rank-score">
                    #{item.rank} | {item?.user?.ecoCycleScoreNum} Impact Score
                  </div>
                  <div className="tile details">
                    {item?.user?.userDetailsInfo?.closetName || "Anonymous Closet"} | {item?.user?.itemPostedNum} Items Posted |{" "}
                    {item?.user?.itemPostedNum * 25} kg of CO2 Saved
                  </div>
                  <Link
                  to={
                    userInfo && userInfo._id == item._id
                      ? { pathname: "/user/my-closet" }
                      : {
                          pathname: `/closet/${item?.user?.userDetailsInfo?.closetName}`,
                          search: `?closet=${item?.user?.userMasterInfo?._id}`,
                        }
                  }
                  state={{ id: item?.user?.userMasterInfo?._id }}
                >
                  <button className="view-closet-btn">View Closet</button>
                </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Leaderboard;
