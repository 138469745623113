import React, { useEffect, useState } from "react";
import "./MyClosetBanner.css";
import {
  FaBookmark,
  FaCalendar,
  FaChartArea,
  FaInfo,
  FaInfoCircle,
  FaRecycle,
  FaStar,
  FaUsers,
} from "react-icons/fa";
import { PiCoatHangerFill } from "react-icons/pi";
import { GiThreeLeaves } from "react-icons/gi";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { MdBarChart, MdSell } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserBannerInfo, reset } from "../StateSlices/getUserBannerInfo";
import Modal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";

const MyClosetBanner = ({ propBannerInfo }) => {
  console.log("User Info as a Banner", propBannerInfo)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [bannerInfoData, setBannerInfoData] = useState(null);

  const { userBannerInfo } = useSelector((state) => state.userbannerinfo);

  useEffect(() => {
    const token = localStorage.getItem("clttoken");
    if (token) {
      setLoading(true);
      dispatch(getuserBannerInfo({ token: token }));
    } else navigate("/login");
  }, []);

  useEffect(() => {
    if (userBannerInfo) {
      setLoading(false);
      setBannerInfoData(userBannerInfo?.userStats || null);
      dispatch(reset());
    }
  }, [userBannerInfo]);

  useEffect(() => {
    if (bannerInfoData) {
      setData([
        {
          id: 1,
          title:
            "Item Posted : " +
            (bannerInfoData.itemPosted
              ? parseInt(bannerInfoData.itemPosted).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of items posted by you",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Potential Carbon Savings",
              numInfo:
                (bannerInfoData.itemPosted
                  ? (parseInt(bannerInfoData.itemPosted) * 25).toLocaleString(
                      "en-US"
                    )
                  : 0) + "Kg CO2",
              tooltip: "Amount of carbon saved by you",
            },
            {
              icon: <MdSell size={"1.7em"} />,
              paragraph: "Posted Item Original Value",
              numInfo:
                "$" +
                (bannerInfoData.itemsPostedOriginalValue
                  ? parseInt(
                      bannerInfoData.itemsPostedOriginalValue
                    ).toLocaleString("en-US")
                  : 0),
              tooltip: "Posted Items Original Value",
            },
            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Posted Item Hanger Value",
              numInfo:
                "$" +
                (bannerInfoData.itemPostedHangerValue
                  ? parseInt(
                      bannerInfoData.itemPostedHangerValue
                    ).toLocaleString("en-US")
                  : 0),
              tooltip: "Posted Items Hanger Value",
            },
          ],
        },
        {
          id: 2,
          title:
            "Item Purchased : " +
            (bannerInfoData.itemPurchased
              ? parseInt(bannerInfoData.itemPurchased).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of items purchased by you",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Actual Carbon Savings",
              numInfo:
                (bannerInfoData.itemPurchased
                  ? (
                      parseInt(bannerInfoData.itemPurchased) * 25
                    ).toLocaleString("en-US")
                  : 0) + "Kg CO2",
              tooltip: "Amount of actual carbon savings",
            },
            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Saved vs. other thrift stores",
              numInfo: bannerInfoData.savedVsOtherThrift
                ? parseInt(bannerInfoData.savedVsOtherThrift) > 0
                  ? "$" +
                    parseInt(bannerInfoData.savedVsOtherThrift).toLocaleString(
                      "en-US"
                    )
                  : "few order away"
                : "$" + 0,
              tooltip: "Amount saved vs. other thrift stores",
              // subtitle: "$845 estimated cost @ avg $30/item",
            },
            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Purchased Item Hanger Value",
              numInfo:
                "$" +
                (bannerInfoData.itemPurchasedHangerValue &&
                bannerInfoData.itemPurchasedHangerValue.indexOf("-") === -1
                  ? parseInt(
                      bannerInfoData.itemPurchasedHangerValue
                    ).toLocaleString("en-US")
                  : 0),
              subtitle:
                "$" +
                (bannerInfoData.subtextSaved
                  ? parseInt(bannerInfoData.subtextSaved).toLocaleString(
                      "en-US"
                    )
                  : 0),
              tooltip: "Purchased Item Hanger Value",
            },
          ],
        },
        {
          id: 3,
          title:
            "Item Sold : " +
            (bannerInfoData.itemSold
              ? parseInt(bannerInfoData.itemSold).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of sold items",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Actual Carbon Savings",
              numInfo:
                (bannerInfoData?.itemSold
                  ? (parseInt(bannerInfoData?.itemSold) * 25).toLocaleString(
                      "en-US"
                    )
                  : 0) + "Kg CO2",
              tooltip: "Amount of actual carbon saved by you by selling",
            },
            {
              icon: <FaUsers size={"1.7em"} />,
              paragraph: "Sold items to",
              numInfo:
                (bannerInfoData?.uniqueEcoSavers
                  ? parseInt(bannerInfoData?.uniqueEcoSavers).toLocaleString(
                      "en-US"
                    )
                  : 0) + " EcoSavers",
              tooltip: "NUmber of Unique Customers",
            },
            {
              icon: <FaStar size={"1.5em"} />,
              paragraph: "Average Rating of",
              numInfo:
                (bannerInfoData.avgClosetRating
                  ? parseInt(bannerInfoData.avgClosetRating).toLocaleString(
                      "en-US"
                    )
                  : 0) + " stars",
              tooltip: "Average Closet Rating",
            },
          ],
        },
      ]);

      // const timer = setInterval(() => {
      //   setScore((prevScore) => {
      //     if (prevScore < parseInt(bannerInfoData.ecoCycleScore)) {
      //       return prevScore + 1;
      //     } else {
      //       clearInterval(timer);
      //       return prevScore;
      //     }
      //   });
      // }, 25);

      // return () => clearInterval(timer);

      if (bannerInfoData && bannerInfoData.ecoCycleScore) {
        let start = null;
        const duration = 1000; // Duration in milliseconds for the entire animation
        const targetScore = parseInt(bannerInfoData.ecoCycleScore);

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const newScore = Math.min(
            targetScore,
            Math.floor((progress / duration) * targetScore)
          );
          setScore(newScore);
          if (progress < duration && newScore < targetScore) {
            requestAnimationFrame(step);
          }
        };

        requestAnimationFrame(step);

        // Cleanup function
        return () => cancelAnimationFrame(step);
      }
    } else {
      setLoading(false);
    }
  }, [bannerInfoData]);

  const [score, setScore] = useState(0);
  const [scoreInfoModal, setScoreInfoModal] = useState(false);
  const [copyText, setCopyText] = useState("Copy Link")

  // useEffect(() => {
  //   if (propBannerInfo) {
  //     // calculateUserData();
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  return (
    <>
      <div className="my-closet-banner-con">
        {
          loading && !userBannerInfo ? (
            <div
              className="my-closet-banner-wrapper background-text"
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="h3 background-text" style={{ marginTop: 0 }}>
                Please Hang on, Generating Report!
              </h3>

              <div className="loader-banner"></div>
            </div>
          ) : (
            // bannerInfoData && !loading ? (
            <>
              <Modal
                ariaHideApp={false}
                isOpen={scoreInfoModal}
                onRequestClose={() => setScoreInfoModal(false)}
                style={{
                  overlay: {
                    background: "rgba(0,0,0,0.5)",
                    zIndex: 70,
                  },
                  content: {
                    maxWidth: "800px",
                    maxHeight: "fit-content",
                    margin: "auto",
                    position: "absolute",
                    bottom: "-5rem",
                  },
                }}
                contentLabel="Example Modal"
              >
                <h3 className="bold-text background-text">
                  Environmental Impact Score Info:
                </h3>
                <p>
                  <strong>Posted Products Count: </strong>
                  <ul>
                    <li>
                      Each time a user posts a product for sale on the platform,
                      it contributes to their product posting count.
                    </li>
                  </ul>
                </p>
                {/* <p>
                Rank:{" "}
                {bannerInfoData?.userRankInfo?.userProducts?.userProductsRank}
              </p> */}
                <br />
                <p>
                  <strong>Purchase Products Count: </strong>

                  <ul>
                    <li>
                      Whenever a user makes a purchase from the platform, it
                      adds to their product purchasing count.
                    </li>
                  </ul>
                </p>
                {/* <p>
                Rank:{" "}
                {bannerInfoData?.userRankInfo?.userPurchases?.userPurchaseRank}
              </p> */}
                <br />
                <p>
                  <strong>Sold Products Count: </strong>
                  <ul>
                    <li>
                      Selling count represents the number of products a user has
                      successfully sold on the platform.
                    </li>
                  </ul>
                </p>
                {/* <p>Rank: {bannerInfoData?.userRankInfo?.userSold?.rank}</p> */}
              </Modal>

              <div>
                <div className="eco-slider-container">
                  <div className="eco-slider">
                    <div
                      className="eco-slider-filled"
                      style={{ width: `${score}%` }}
                    >
                      <span
                        style={{
                          float: "right",
                          color: "var(--white-color)",
                          marginRight: ".75rem",
                        }}
                      >
                        {window.innerWidth > 767 && score >= 10 && (
                          // parseInt(
                          //   bannerInfoData && bannerInfoData.ecoCycleScore
                          // )
                          <span style={{ transition: ".3s all ease" }}>
                            {score > 25
                              ? "Environmental Impact Score: "
                              : "Score:"}
                          </span>
                        )}
                        <span
                          style={{
                            position: score < 98 ? "absolute" : "static",
                            right: score < 98 ? "-2rem" : 0,
                          }}
                        >
                          {score}
                        </span>
                      </span>
                    </div>

                    <div className="eco-slider-empty ">
                      <span
                        className="eco-label"
                        // title="See how we calculate EcoCycle Score!"
                      >
                        <FaInfoCircle
                          onClick={() => setScoreInfoModal(true)}
                          color="#fff"
                          size={"1.4em"}
                        />
                        <span
                          className="tooltip-text-ecolabel"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          See how we calculate EcoCycle Score!
                        </span>
                        {/* <div class="eco-label-tooltip h6">
                        See how we calculate EcoCycle Score!
                      </div> */}
                      </span>
                    </div>
                    {/* <div className="eco-score">
                  {score}
                  <span className="label">ecocycle score</span>
                </div> */}
                  </div>
                </div>
                <div className="my-closet-banner-wrapper-con">
                  <div className="my-closet-banner-wrapper background-text">
                    <div
                      className="banner-img-con banner-img-con2 background-text"
                      style={{ width: "200px" }}
                    >
                      <img
                        alt="user profile"
                        src={
                          propBannerInfo?.userIcon ||
                          "https://static.thenounproject.com/png/363640-200.png"
                        }
                      />

                      <h3
                        className="bold-text background-text"
                        style={{ margin: ".5rem auto", lineBreak: "anywhere" }}
                      >
                        {propBannerInfo?.closetName}
                      </h3>
                      <h5 className="">
                        {propBannerInfo?.userCity},{" "}
                        {propBannerInfo?.userState}
                      </h5>

                      {bannerInfoData && bannerInfoData.memberSinceDateTime && (
                        <h6 style={{ textTransform: "capitalize" }}>
                          {/* Member since{" "}
                      {new Date(
                        bannerInfoData.memberSinceDateTime
                      ).toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })} */}
                          Membership Type: &nbsp;
                          {propBannerInfo?.membership}
                        </h6>
                      )}
                      {propBannerInfo?.referId && (
                        <button
                          className="copy-link-btn"
                          onClick={() => {
                            setCopyText("Copied!")
                            toast.success("Referral URL copied successfully!", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,})
                            setTimeout(() => {
                              setCopyText("Copy Link")
                            }, 5000)
                            navigator.clipboard.writeText(
                              "https://closestcloset.com/signup?referral=" +
                                encodeURIComponent(
                                  propBannerInfo?.referId
                                )
                            );
                          }}
                        >
                          {copyText}
                        </button>
                      )}
                    </div>
                    {data.map((s) => (
                      <div className="gamification-col">
                        <div
                          data-tooltip-id={s.title}
                          className="gami-title bold-text h5"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="icon"
                            style={{ marginRight: "1rem", lineHeight: "10px" }}
                          >
                            <MdBarChart size={"1.25em"} />
                          </div>{" "}
                          <div>{s.title}</div>
                        </div>
                        <ReactTooltip
                          className="tooltipCustom"
                          id={s.title}
                          place="top"
                          content={s.titleTooltip}
                        ></ReactTooltip>
                        {s.content.map((ss, ind) => (
                          <div
                            className="info-row"
                            data-tooltip-id={ss.paragraph}
                            style={{ marginTop: ind === 0 ? "2rem" : "0" }}
                          >
                            <div className="icon">{ss.icon}</div>
                            <div className="content">
                              <p>{ss.paragraph}</p>
                              <h3 className="background-text bold-text">
                                {ss.numInfo}
                              </h3>
                              <ReactTooltip
                                className="tooltipCustom"
                                id={ss.paragraph}
                                place="left"
                                content={ss.tooltip}
                              ></ReactTooltip>
                              {ss.subtitle &&
                              ss.subtitle.indexOf("-") === -1 ? (
                                <small>
                                  <span style={{ color: "green" }}>
                                    {ss.subtitle}
                                  </span>{" "}
                                  save vs. your membership fees
                                </small>
                              ) : (
                                <>{ind === 2 && <small>&nbsp;</small>}</>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="h6 grey-text update-info-msg">
                    <FaInfoCircle
                      data-tooltip-id="score-update-info"
                      color="var(--grey-color)"
                    />{" "}
                    &nbsp; Score is updated every 2 hours
                  </div>
                  <ReactTooltip
                    className="tooltipCustom"
                    id="score-update-info"
                    place="bottom"
                    content="Score is
                    updated every 2 hours"
                  />
                </div>
              </div>
              {/* </SplideSlide>
      </Splide> */}
            </>
          )
          // ) : (
          //   <>
          //     <div className="my-closet-banner-wrapper background-text">
          //       <h3>Banner Info Not Found</h3>
          //     </div>
          //   </>
          // )
        }
      </div>
    </>
  );
};

export default MyClosetBanner;
