import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  getProducts,
  resetUpdateStatus,
  deleteProduct,
  unbanProduct,
  getCheckoutOrders,
} from "../../../components/StateSlices/adminSlice";
import "./Orders.css";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaFileDownload, FaUndo } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import hanger from "../../../assets/images/hanger.svg";
import Pagination from "../../../components/Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Modal from "react-modal";
import Select from "react-select";

import "react-toastify/dist/ReactToastify.css";
import { getDataExport } from "../../../components/StateSlices/adminDataExportSlice";
import {
  checkoutStatusChange,
  fulfilled,
  orderStatusChange,
  resetFulfill,
} from "../../../components/StateSlices/fulfillSlice";

const Products = ({ tab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [statisState, setStatisState] = useState(false);
  const allUsersInfo = [{}, {}, {}];
  const allUserStatus = false;

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { allOrdersStatus, allOrdersInfo, allOrdersError, totalCount } =
    useSelector((state) => state.admin);

  const { fulFillError, fulFillStatus, fulFillInfo } = useSelector(
    (state) => state.fulfilled
  );

  // sconst { dataStatus, dataInfo } = useSelector((state) => state.getDataExport);

  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [orderSearchVal, setOrderSearchVal] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     borderColor: state.isFocused ? "#F26654" : "inherit",
  //     width: "100%",
  //     boxShadow: "none",
  //     "&:hover": {
  //       borderColor: "#F26654",
  //     },
  //     fontSize: "14px",
  //     borderRadius: 0,
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     textTransform: "capitalize",
  //     marginTop: "3px",
  //     color: state.isSelected ? "#fff" : "#000",
  //     backgroundColor: state.isSelected ? "#F26654" : "#fff",
  //     "&:hover": {
  //       backgroundColor: state.isSelected
  //         ? "#F26654"
  //         : "rgba(242, 102, 84, 0.5)",
  //       color: "#fff",
  //     },
  //   }),
  //   // placeholder:(provided,state) => ({
  //   //   ...provided,
  //   //   textTransform: "capitalize",
  //   // }),
  //   singleValue: (provided, state) => ({
  //     ...provided,
  //     textTransform: "capitalize",
  //   }),
  //   menu:(provided,state) => ({
  //     ...provided,
  //     zIndex:2222222
  //   })
  // };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ==
          pageNumber
        ) {
          dispatch(
            getCheckoutOrders({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: orderSearchVal,
              tab: tab,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        // console.log("This");
        dispatch(
          getCheckoutOrders({
            token,
            p: pageNumber,
            search: orderSearchVal,
            tab: tab,
          })
        );
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab]);

  useEffect(() => {
    if (
      allOrdersInfo &&
      (allOrdersInfo.length > 0 || allOrdersStatus === "succeeded")
    ) {
      setOrderDetails(allOrdersInfo);
      console.log("herer", allOrdersInfo);
    }
  }, [allOrdersInfo]);

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/orders?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(getCheckoutOrders({ token, p: 0 }));
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  const downloadOrdersCsv = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        getDataExport({
          token,
          model: "checkouts",
          query: "active",
        })
      );
      toast.info("Please Wait for a While", toastOption);
    }
  };

  const handleFulfill = (pId, cId, status, e) => {
    swal({
      title: `Mark this item ${status}`,
      text:
        status == "not fulfilled"
          ? `Are you sure this item was not received by the user?`
          : status == "fulfilled"
          ? "Are you sure this item was received by the user?"
          : status == "cancelled"
          ? "Are you sure this item was cancelled by the user?"
          : status == "missing"
          ? "Are you sure this item is missing from the store?"
          : "Are you sure this item to put it on hold?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(checkoutStatusChange({ token, pId, cId, status }));

        let temp = orderDetails.map((ord) => {
          if (ord._id === cId) {
            return {
              ...ord,
              products: ord.products.map((prod) => {
                if (prod._id === pId) {
                  return {
                    ...prod,
                    params: prod.params.map((param, index) => {
                      if (index === 0) {
                        // Update only the first element in the params array
                        return {
                          ...param,
                          status: status,
                        };
                      }
                      return param;
                    }),
                  };
                }
                return prod;
              }),
            };
          }
          return ord;
        });

        setOrderDetails(temp);

        let temp2 = modalData.products.map((prod) => {
          if (prod._id === pId) {
            return {
              ...prod,
              params: prod.params.map((param, index) => {
                if (index === 0) {
                  // Update only the first element in the params array
                  return {
                    ...param,
                    status: status,
                  };
                }
                return param;
              }),
            };
          }
          return prod;
        });

        setModalData((prevModalData) => ({
          ...prevModalData,
          products: temp2,
        }));
      } else {
        let previousProdInfo = modalData.products.filter(
          (prod) => prod._id == pId
        );
        e.target.value = previousProdInfo[0].params[0].status
          ? previousProdInfo[0].params[0].status
          : "not fulfilled";
      }
    });
  };

  const handleComplete = (cId) => {
    swal({
      title: `Mark Order Complete `,
      text: "Do you want to mark this complete? (Customer has picked up the order or it has been shipped)",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(orderStatusChange({ token, cId }));
        // set
      }
    });
  };

  const orderStatusOption = [
    { label: "NotFullfilled", value: 1 },
    { label: "Fulfilled", value: 2 },
    { label: "Cancelled", value: 3 },
    { label: "Missing Item", value: 4 },
  ];

  useEffect(() => {
    if (fulFillInfo === "fulfilled") {
      toast.success(`Ordered Item Status Sucessfully Updated!`, toastOption);
      dispatch(resetFulfill());
      // let token = localStorage.getItem("clttoken");
      // dispatch(
      //   getCheckoutOrders({
      //     token,
      //     p: pageNumber,
      //     search: orderSearchVal,
      //   })
      // );
      if(tab === "completed"){
        setIsOpen(false);
        let token = localStorage.getItem("clttoken");
        dispatch(
          getCheckoutOrders({
            token,
            p: pageNumber,
            search: orderSearchVal,
            tab:tab
          })
        );
      }
    } else if (fulFillInfo === "orderfulfilled") {
      toast.success(`Ordered Item Status Sucessfully Updated!`, toastOption);
      dispatch(resetFulfill());
      setIsOpen(false);
      let token = localStorage.getItem("clttoken");
      dispatch(
        getCheckoutOrders({
          token,
          p: pageNumber,
          search: orderSearchVal,
        })
      );
    }
  }, [fulFillInfo]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "850px",
            height: "fit-content",
            maxHeight: "500px",
            margin: "auto",
            position: "absolute",
            zIndex: 111,
          },
        }}
        contentLabel="Example Modal"
      >
        {/* <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => setIsOpen(false)}
          >
            X
          </button>
        </div> */}

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="h3 background-text bold-text">Order Info</div>
              {/* <Link
                // state={modalData}
                // to={`/admin/checkout/${modalData?._id}`}
                to={{
                  pathname: `/admin/checkout/${modalData?._id}`,
                  state: { modalData: modalData }, // Pass modalData as state
                }}
                target="_blank"
              > */}
              <div
                className="btn h6"
                onClick={() => {
                  const url = `/admin/checkout/${modalData?._id}`;

                  // Open the URL in a new tab
                  const newTab = window.open(url, "_blank");

                  if (newTab) {
                    // Use localStorage to pass data to the new tab
                    localStorage.setItem(
                      "adminOrderModalData",
                      JSON.stringify(modalData)
                    );
                  }
                }}
              >
                View More
              </div>
              {/* </Link> */}
            </div>
            <hr
              style={{
                borderStyle: "solid",
                borderColor: "var(--primary-color)",
                width: "50px",
                borderWidth: "2px",
                marginBottom: "20px",
              }}
            ></hr>
            <br />
            <form>
              <div style={{ width: "100%" }}>
                <span className="item_title" style={{ minWidth: "120px" }}>
                  User Email :&nbsp;
                </span>
                {modalData && modalData.userEmail}
              </div>
              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <span className="item_title" style={{ minWidth: "180px" }}>
                  Shipping Details :{" "}
                </span>
                {modalData?.shippingCity +
                  ", " +
                  modalData?.shippingState +
                  ", " +
                  modalData?.shippingZipCode}
              </div>
              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <span className="item_title" style={{ minWidth: "120px" }}>
                  Price Paid :&nbsp;
                </span>
                {(modalData && modalData?.pricePaid) || 0}
              </div>
              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <span className="item_title" style={{ minWidth: "120px" }}>
                  Total Hanger Paid :&nbsp;
                </span>
                {(modalData && modalData?.hangerPaid) || 0}
              </div>
              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <span className="item_title" style={{ minWidth: "120px" }}>
                  Total Hanger Purchased(by Money) :&nbsp;
                </span>
                {(modalData && modalData?.hangerPurchased) || 0}
              </div>
              {modalData && modalData?.trackingUrl && (
                <>
                  <div style={{ width: "100%", marginTop: ".5rem" }}>
                    <span className="item_title" style={{ minWidth: "120px" }}>
                      Tracking Url :&nbsp;
                    </span>
                    {(modalData && modalData?.trackingUrl) || 0}
                  </div>
                  <div style={{ width: "100%", marginTop: ".5rem" }}>
                    <span className="item_title" style={{ minWidth: "120px" }}>
                      Shipping Label :&nbsp;
                    </span>
                    {(modalData && modalData?.labelUrl) || 0}
                  </div>
                </>
              )}

              <div style={{ width: "100%", marginTop: ".5rem" }}>
                <span className="item_title" style={{ minWidth: "120px" }}>
                  Order Status :&nbsp;
                </span>
                {modalData?.products.every((product) =>
                  product.params.every(
                    (param) =>
                      // param.status == "fulfilled"
                      ["fulfilled", "cancelled", "missing"].includes(
                        param.status
                      ) && param.fulfilled == false
                  )
                ) ? (
                  <>
                    <span>On Hold / Processed</span>
                    <button
                      className="btn h6"
                      style={{ marginLeft: "8px" }}
                      type="button"
                      onClick={(e) => {
                        handleComplete(modalData?._id);
                      }}
                    >
                      Mark Order Complete
                    </button>
                  </>
                ) : modalData?.products.every((product) =>
                    product.params.some(
                      (param) => param.fulfilled == false
                      // ["fulfilled"].includes(param.status)
                    )
                  ) ? (
                  "Not FullFilled"
                ) : (
                  "FullFilled"
                )}
              </div>
              <div className="orders-admin">
                {modalData?.products.map((prod, ind) => {
                  // console.log(prod, modalData);
                  return (
                    <div class="product" key={ind} style={{ padding: "1rem" }}>
                      <div class="product-img">
                        <LazyLoadImage
                          style={{ borderRadius: "5px" }}
                          src={
                            prod.productImage[0]
                              ? prod.productImage[0]
                              : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                          }
                          alt="FP1"
                          effect="blur"
                        />
                        {/* <img src="https://placehold.it/500x500" /> */}
                      </div>
                      <div class="product-content">
                        <h3 className="background-text">{prod.productName}</h3>
                        <div className="product-details-row">
                          <div className="col">
                            <em
                              style={{
                                margin: "8px 0",
                                color: "var(--dark-grey-color)",
                              }}
                            >
                              {prod.closetName}
                            </em>
                            <hr className="product-hr" />
                            <div className="bold-text h6">
                              <span class="product-text price">
                                $&nbsp;{prod.productPrice}
                              </span>
                              <span
                                style={{
                                  margin: "auto 1rem",
                                  color: "var(--background-color)",
                                }}
                              >
                                |
                              </span>
                              <span class="product-text genre">
                                <img
                                  style={{ height: "14px" }}
                                  src={hanger}
                                  alt="hanger icon"
                                />{" "}
                                {prod.productHanger}
                              </span>
                            </div>

                            <div
                              className="bold-text h6"
                              style={{ marginTop: "10px" }}
                            >
                              {/* <button className="btn" type="button" onClick={()=>handleFulfill(prod._id,modalData?._id)} disabled={prod.params[0].fulfilled?true:false} style={prod.params[0].fulfilled?{padding:"6px 20px"}:{padding:"6px 20px",background:"#f2998f"}}>
                                  {
                                    prod.params[0].fulfilled?"FulFilled!":"FulFilled?"
                                  }
                        
                            </button> */}
                              <select
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  handleFulfill(
                                    prod._id,
                                    modalData?._id,
                                    e.target.value,
                                    e
                                  );
                                }}
                                style={{ padding: "8px" }}
                                // disabled={
                                //   prod.params[0].status &&
                                //   (prod.params[0].status == "cancelled" ||
                                //     prod.params[0].status == "missing")
                                //     ? true
                                //     : false
                                // }
                              >
                                <option
                                  value="not fulfilled"
                                  selected={
                                    prod.params[0].status &&
                                    prod.params[0].status == "not fulfilled"
                                      ? true
                                      : false
                                  }
                                >
                                  Not Fulfilled
                                </option>
                                <option
                                  value="fulfilled"
                                  selected={
                                    prod.params[0].status &&
                                    prod.params[0].status == "fulfilled"
                                      ? true
                                      : false
                                  }
                                >
                                  Fulfilled
                                </option>
                                <option
                                  value="cancelled"
                                  selected={
                                    prod.params[0].status &&
                                    prod.params[0].status == "cancelled"
                                      ? true
                                      : false
                                  }
                                >
                                  Cancelled
                                </option>
                                <option
                                  value="missing"
                                  selected={
                                    prod.params[0].status &&
                                    prod.params[0].status == "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  Missing
                                </option>
                                {/* <option
                                  value="hold"
                                  selected={
                                    prod.params[0].status &&
                                    prod.params[0].status == "hold"
                                      ? true
                                      : false
                                  }
                                >
                                  Hold
                                </option> */}
                              </select>
                              {/* <Select

                              options={orderStatusOption}
                              onChange={(selectedOption) => {
                                
                              }}
                              //value={[{label:"notFullfilled",value:1}]}
                              styles={{
                                ...customStyles,
                              }}
                              placeholder="Order Status"
                            ></Select> */}
                            </div>
                          </div>
                          <div className="col">
                            <div className="h6">
                              <span className="bold-text background-text">
                                Brand :{" "}
                              </span>
                              <span>{prod.productStats?.brand}</span>
                            </div>

                            <div className="h6">
                              <span className="bold-text background-text">
                                Category :{" "}
                              </span>
                              <span>{prod.productStats?.category + ","}</span>
                            </div>

                            <div className="h6">
                              <span className="bold-text background-text">
                                Condition :{" "}
                              </span>
                              <span>{prod.productStats?.condition}</span>
                            </div>

                            <div className="h6">
                              <span className="bold-text background-text">
                                Stock :{" "}
                              </span>
                              <span>{prod.productStock}</span>
                            </div>

                            <div className="h6">
                              <span className="bold-text background-text">
                                Size :{" "}
                              </span>{" "}
                              <span>{prod.productStats?.size}</span>
                            </div>

                            <div className="h6">
                              <span className="bold-text background-text">
                                Status :{" "}
                              </span>{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {prod.params[0].status
                                  ? prod.params[0].status
                                  : "Not Fullfilled"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-operations">
                        <div
                          className="action_icon"
                          style={{
                            width: "100%",
                            margin: "auto",
                          }}
                        >
                          <Link
                            to={`/product/${encodeURIComponent(
                              prod.productStats.brand
                            )}-${encodeURIComponent(prod.productName)}/${
                              prod._id
                            }`}
                            state={{ id: prod._id }}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <div className="btn">
                              <i class="fas fa-eye"></i>
                            </div>
                          </Link>
                          {/* <Link
                            to={`/editproduct/${encodeURIComponent(
                              prod.productStats.brand
                            )}-${encodeURIComponent(prod.productName)}`}
                            state={{ id: prod._id,from:"admin" }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="btn">
                              <i class="fas fa-pen"></i>
                            </div>
                          </Link> */}
                          {/* <Link to={"/my-closet"}> */}

                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="adm-prod-con">
        <div className="adm-prod-wrapper">
          <div className="products-header">
            <div
              className="h2 background-text bold-text"
              style={{ minWidth: "30%" }}
            >
              Manage Orders
            </div>
            <div className="input_container search_closet orders_search">
              <form
                className="input_container_wrapper"
                onSubmit={(e) => {
                  e.preventDefault();
                  let token = localStorage.getItem("clttoken");
                  if (token) {
                    // setPageNumber(0)
                    navigate(`/admin/orders?page=1`, { replace: true });
                    if (
                      location.search.slice(-1) &&
                      location.search.slice(location.search.indexOf("=") + 1) -
                        1 ==
                        0
                    ) {
                      dispatch(
                        getCheckoutOrders({
                          token,
                          p: 0,
                          search: orderSearchVal,
                        })
                      );
                    }
                  } else {
                    navigate("/404", { replace: true });
                  }
                }}
                style={{ display: "flex", flex: 1, alignItems: "center" }}
              >
                <div style={{ display: "flex", flex: 1 }}>
                  <input
                    type="text"
                    id="userSearch"
                    name="userSearch"
                    onChange={(e) => setOrderSearchVal(e.target.value)}
                    value={orderSearchVal}
                    placeholder="Search by User Email"
                    style={{
                      marginTop: "0px",
                      padding: "10px 12px",
                      borderRadius: "5px 0 0 5px",
                      fontFamily: "inherit",
                    }}
                  />
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      borderRadius: "0 5px 5px 0",
                      padding: "10px 12px",
                    }}
                  >
                    <i class="fas fa-search fa-lg"></i>
                  </button>
                </div>
                <div>
                  {orderSearchVal && (
                    <span
                      className="small-text"
                      style={{
                        marginRight: 0,
                        marginLeft: "10px",

                        cursor: "pointer",
                        color: "var(--background-color)",
                        fontSize: "13px",
                        fontFamily: "LATO",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        resetSearch();
                        setOrderSearchVal("");
                      }}
                    >
                      Reset <FaUndo style={{ fontSize: "11px" }} />
                    </span>
                  )}
                </div>
                <button
                  style={{
                    padding: "5px 12px",
                    marginLeft: "1rem",
                    // marginTop: "5px",
                  }}
                  type="button"
                  className="btn"
                  onClick={downloadOrdersCsv}
                  title="Download CSV File of All Checkout Orders"
                >
                  <FaFileDownload size={"1.7em"} />
                </button>
              </form>
            </div>
          </div>

          <table className="users-table" style={{ width: "100%" }}>
            {allOrdersStatus === "loading" ? (
              <Spinner1 />
            ) : (
              <>
                {orderDetails && orderDetails.length == 0 ? (
                  <div
                    style={{ marginTop: "1rem", marginBottom: "4rem" }}
                    className="no_item"
                  >
                    <h1 className="h1" style={{ marginTop: "1rem" }}>
                      No Order Found
                    </h1>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>User Email</th>
                        <th>Purchased Date</th>
                        <th>Ordered By</th>
                        <th>Ordered Status</th>
                        <th>Shipping</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails &&
                        orderDetails.length > 0 &&
                        orderDetails.map((order, index) => {
                          return (
                            <tr key={index}>
                              <td className="h6">{order.orderId}</td>
                              <td className="h6">{order.userEmail}</td>
                              <td className="h6">{order.checkoutTime}</td>
                              <td
                                className="h6"
                                style={{ textTransform: "capitalize" }}
                              >
                                {order.orderdBy ? order.orderdBy : "User"}
                              </td>
                              <td className="h6">
                                {order.products.every((product) =>
                                  product.params.every((param) => {
                                    // param.status == "fulfilled"
                                    // (param.status === "fulfilled" || param.status === "cancelled" || param.status === "missing")
                                    return (
                                      [
                                        "fulfilled",
                                        "cancelled",
                                        "missing",
                                      ].includes(param.status) &&
                                      param.fulfilled == false
                                    );
                                  })
                                ) ? (
                                  <div style={{
                                    display: "flex",
                                    flexDirection:"column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap:"0.3rem"
                                  }}>
                                    <span style={{ color: "blue" }}>
                                      On Hold / Processed
                                    </span>
                                    <button
                                      className="btn h6"
                                      style={{ marginLeft: "8px" }}
                                      type="button"
                                      onClick={(e) => {
                                        handleComplete(order?._id);
                                      }}
                                    >
                                      Mark Order Complete
                                    </button>
                                  </div>
                                ) : order.products.every((product) =>
                                    product.params.some(
                                      (param) => param.fulfilled == false
                                    )
                                  ) ? (
                                  <span style={{ color: "red" }}>
                                    Not Completely Fulfilled
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    Completely Fulfilled
                                  </span>
                                )}
                              </td>
                              <td className="h6">
                                {order.trackingUrl ? (
                                  <a
                                    href={order.trackingUrl}
                                    style={{
                                      textDecoration: "underline",
                                      color: "var(--background-color)",
                                    }}
                                  >
                                    {order.labelUrl}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="h6">
                                <button
                                  style={{
                                    background: "transparent",
                                    color: "var(--primary-color)",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setIsOpen((prev) => !prev);
                                    setModalData(order);
                                  }}
                                >
                                  <i class="fas fa-eye"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </>
            )}
          </table>
          {totalCount > 20 && (
            <Pagination
              pageCount={Math.ceil(totalCount / 20)}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
