import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  userInfo: null,
  error: null,
  profile: null,
  userProductCount: null
};

export const getuserInfo = createAsyncThunk(
  "user/getuserInfo",
  async ({ token, p=0,search="",id="" }, { rejectWithValue }) => {
    try {
      console.log(search)
      let s = ''
      if(search){
        s = `&search=${search}`
      }
      if(id){
        s = `&id=${id}`
      }
      const { data } = await axios.get(`/api/users/info?p=${p}${s}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const userInfoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset(state, action) {
      state.error = null;
      state.profile = null;
      state.userInfo = null;
      state.userProductCount = null;
    },
    resetStatus(state,action){
      state.status="idle"
    }
  },
  extraReducers: {
    [getuserInfo.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [getuserInfo.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.userInfo = {...action.payload.user,totalProduct:action.payload.totalProduct};
      state.profile = action.payload.user.userDetailsId.profileStatus;
      state.userProductCount = action.payload.userProductsCount;
    },
    [getuserInfo.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reset,resetStatus } = userInfoSlice.actions;
export default userInfoSlice.reducer;
