import React, { useEffect, useState } from "react";
import MyClosetBanner from "./MyClosetBanner";
import MyClosetSidebar from "./MyClosetSidebar";
import Tabs from "../../admin/components/Tabs/Tabs";
import MyCloset from "../../pages/MyCloset";
import MyPurchases from "../MyPurchases/MyPurchases";
import MyClosetRightSection from "./MyClosetRightSection";
import { useDispatch, useSelector } from "react-redux";
import { getuserInfo, resetStatus } from "../StateSlices/getuserSlice";
import Spinner from "../Spinner/Spinner";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { referLink, resetItem } from "../StateSlices/referLinkSlice";
import { useNavigate } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";

const MyClosetNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);

  const [pSpinner, setPSpinner] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      setPSpinner(true);
      dispatch(resetStatus());
      const token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      setPSpinner(false);
    }
  }, [userInfo]);

  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );

  const [isReferModalActive, setIsReferModalActive] = useState(false);
  const [refUrl, setRefUrl] = useState("");
  // console.log("header2", userInfo, profile);
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://closestcloset.com/signup?referral=" + encodeURIComponent(referId)
    );
    setIsReferModalActive((prev) => !prev);
  }

  const getUrl = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(referLink({ token: token }));
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {!userInfo && pSpinner ? (
        <Spinner />
      ) : (
        <div
          className="my-closet-con site-width"
          style={{ margin: "2.5rem auto" }}
        >
          <Modal
            ariaHideApp={false}
            isOpen={isReferModalActive}
            onRequestClose={() => setIsReferModalActive(false)}
            style={{
              overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex: 70,
              },
              content: {
                maxWidth: "600px",
                maxHeight: "fit-content",
                margin: "auto",
                position: "absolute",
              },
            }}
            contentLabel="Example Modal"
          >
            <div
              className="close_container"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 9.5px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
            </div>

            <div className="terms-modal-con">
              <div
                className="terms-modal-wrapper"
                style={{
                  marginTop: 0,
                  padding: "1.5rem 0",
                  paddingTop: ".5rem",
                }}
              >
                <h3 className="primary-text" style={{ margin: ".5rem 0" }}>
                  Refer to your friends
                </h3>
                <h6 className="background-text">
                  Members earn 5 bonus hanger credits + $5 off their next
                  subscription fee on each paid member referral!
                </h6>
                <div className="reference-section">
                  <input
                    type="text"
                    id="reference-input"
                    value={refUrl}
                    disabled
                  />
                  <button
                    id="copy-button"
                    onClick={() => {
                      // refUrl.select();
                      // refUrl.setSelectionRange(0, 99999); // For mobile devices
                      // Copy the text inside the text field
                      navigator.clipboard.writeText(refUrl);
                      toast.success(
                        "Referral URL copied successfully!",
                        toastOption
                      );
                    }}
                  >
                    <MdContentCopy />
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {userInfo &&
            userInfo.userDetailsId &&
            (
              <MyClosetBanner propBannerInfo={userInfo.userDetailsId} />
            )}
          <div className="my-closet">
            <div className="my-closet-left" style={{ display: "block" }}>
              <MyClosetSidebar getUrl={getUrl} />
            </div>
            <div className="my-closet-right">
              <MyClosetRightSection />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyClosetNew;
